import React, { useCallback, useEffect, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Prismic from 'prismic-javascript';
import PrismicDOM from 'prismic-dom';
import Gallery from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from 'react-images';
import Lightbox from 'react-image-lightbox';
import { withTrans } from '../i18n/withTrans';
import AppLayout from '../components/AppLayout';
import SEO from '../components/seo';

const apiEndpoint = 'https://mattines.cdn.prismic.io/api/v2';

const DistributheureGallery = ({ photos }) => {
	const [currentImage, setCurrentImage] = useState(0);
	const [viewerIsOpen, setViewerIsOpen] = useState(false);

	const openLightbox = useCallback((event, { photo, index }) => {
		setCurrentImage(index);
		setViewerIsOpen(true);
	}, []);

	photos = photos.map(i => ({
		src: i.img.tmb.url,
		url: i.img.url,
		width: i.img.dimensions.width,
		height: i.img.dimensions.height
	}));

	return (
		<>
			<Gallery photos={photos} onClick={openLightbox} />

			{viewerIsOpen ? (
				<Lightbox
					mainSrc={photos[currentImage].url}
					nextSrc={photos[(currentImage + 1) % photos.length].url}
					prevSrc={photos[(currentImage + photos.length - 1) % photos.length].url}
					onCloseRequest={() => setViewerIsOpen(false)}
					onMovePrevRequest={() => setCurrentImage((currentImage + photos.length - 1) % photos.length)}
					onMoveNextRequest={() => setCurrentImage((currentImage + 1) % photos.length)}
				/>
			) : null}
		</>
	);
};

const AutomattinesPage = () => {
	const [ sections, setSections ] = useState([]);
	const [ titleAddress, setTitleAddress ] = useState('');
	const [ address, setAddress ] = useState([]);
	const [ images, setImages ] = useState([]);

	const data = useStaticQuery(graphql`
	    query {
	      imageAutomattines: file(relativePath: { eq: "automattines.jpg" }) {
	        childImageSharp {
	          fluid(maxWidth: 1920) {
	            ...GatsbyImageSharpFluid_withWebp
	          }
	        }
	      }
	    }
	`);

	useEffect(() => {
		Prismic.getApi(apiEndpoint).then(
			api => api.getByID('X4b1HxEAACYAIoH2', { lang : 'fr-ch' })
		).then(response => {
			setSections(response.data.section);
			setTitleAddress(response.data.sous_titre_adresse.length ? response.data.sous_titre_adresse[0].text : '');
			setAddress(response.data.adresse);
			setImages(response.data.images);
		});
	}, []);

	return (
		<AppLayout>
			<SEO title="L'AutoMattines" />

			<div className="hero-image">
				<Img fluid={data.imageAutomattines.childImageSharp.fluid} />
			</div>

			<div>
				{sections.map((section, key) => (
					<div key={key} className="page-section">
						<div className="section-title">
							<h2>
								{section.titre.length ? section.titre[0].text : ''}
								{section.legume_image.url ? (
									<img src={section.legume_image.url} alt={section.titre.length ? section.titre[0].text : ''} />
								) : null}
							</h2>
							<div className="section-title-line" />
						</div>

						<div className="section-content">
							{section.paragraph.length ? (
								<div className="section-content-text">
									{section.sous_titre.length ? <h3>{section.sous_titre[0].text}</h3> : null}
									<div dangerouslySetInnerHTML={{ __html: PrismicDOM.RichText.asHtml(section.paragraph) }} />
								</div>
							) : null}

							{section.content_image.url ? (
								<div className="section-content-image">
									<img src={section.content_image.url} alt={section.titre.length ? section.titre[0].text : ''} />
								</div>
							) : null}
						</div>
					</div>
				))}

				<div className="page-section" style={{ marginTop: 0 }}>
					<div className="section-content">
						<div className="section-content-text">
							<h3>{titleAddress}</h3>

							<div style={{ textAlign: 'center' }}>
								<div className="address-box">
									{address.map((p, i) => <div key={i}>{p.text}</div>)}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="page-section" style={{ marginTop: 0 }}>
					<div className="section-content">
						<DistributheureGallery photos={images} />
					</div>
				</div>
			</div>
		</AppLayout>
	);
};

export default withTrans(AutomattinesPage);
